import React, { useContext } from 'react';
import {
  NavigationContext,
  UserContext,
  useFirstRender,
} from '@eas/common-web';
import { Login } from '@modules/login/login-new';
import { EvidenceBrowserUrl } from './enums/evidence-url';

export function AppPublic() {
  const { user } = useContext(UserContext);
  const { navigate } = useContext(NavigationContext);

  useFirstRender(async () => {
    if (user) {
      navigate(EvidenceBrowserUrl.HOME);
    }
  });
  return (
    <>
      <Login />
    </>
  );
}
