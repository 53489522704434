import React, { useContext } from 'react';
import {
  DetailContext,
  DetailHandle,
  DictionaryEvidence,
  UserContext,
} from '@eas/common-web';
import { Me, Supplier } from '@models';
import { Permission } from '@enums';

export const SupplierActionBar = () => {
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);
  const {
    source: { data },
  } = useContext<DetailHandle<Supplier>>(DetailContext);
  const ActionBar = DictionaryEvidence.dictionaryActionbarFactory({});

  const showActionBar = data?.active
    ? hasPermission(Permission.Supplier.SUPPLIER_INVALIDATE)
    : hasPermission(Permission.Supplier.SUPPLIER_REVALIDATE);

  return data && showActionBar ? <ActionBar /> : <></>;
};
