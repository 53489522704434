import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ApiFilterOperation,
  DetailContext,
  DetailHandle,
  DetailMode,
  EvidenceContext,
  FormAutocomplete,
  FormContext,
  FormFileField,
  FormLocalDateTimeField,
  FormPanel,
  FormSelect,
  FormTextField,
  UserContext,
  useFormSelector,
} from '@eas/common-web';
import { useAllPersons, usePersons } from '@modules/person/person-api';
import { usePrimaryJournalState } from '@modules/primary-journals/primary-journal-api';
import { useSuppliers } from '@modules/suppliers/supplier-api';
import { useTenants } from '@modules/tenant/tenant-api';
import { JournalEntryField } from '@composite/journal-entry-collection/journal-entry-field';
import { Redirect } from '@components/redirect/redirect';
import { Me, PartialJournal } from '@models';
import {
  EvidenceApiUrl,
  EvidenceBrowserUrl,
  JournalTypeEnum,
  Permission,
} from '@enums';
import { personLabelMapper } from '@utils/label-mapper';
import {
  usePartialJournalStates,
  usePartialJournalTypes,
} from '../partial-journal-api';
import { PARTIAL_JOURNAL_ENTRY } from '../partial-journal-hooked-sources';

export function OverviewFields() {
  const primaryStates = usePrimaryJournalState();
  const { source, mode, isLocked, isExisting } =
    useContext<DetailHandle<PartialJournal>>(DetailContext);
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  const { hookedSources } = useContext(EvidenceContext);

  const hasJournalEntryPermission = hasPermission(
    Permission.JournalEntry.JOURNAL_ENTRY_LIST
  );

  //const memberColumns = useUserColumns();
  const persons = usePersons();
  const tenants = useTenants();
  const primaryJournalId = source.data?.parent?.id ?? '';
  const journalId = source.data?.id ?? '';
  const journalEntrySource = hookedSources[PARTIAL_JOURNAL_ENTRY]?.source;

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__PARTIAL_JOURAL__DETAIL__PANEL_TITLE__PARENT"
            defaultMessage="Základné údaje hlavného denníka"
          />
        }
        defaultExpanded={false}
        expandable
      >
        <FormTextField
          name="parent.construction.name"
          label={
            <FormattedMessage
              id="ESD__PARTIAL_JOURAL__DETAIL__FIELD_LABEL__PARENT_CONSTRUCTION_NAME"
              defaultMessage="Názov stavby"
            />
          }
          disabled
          after={
            hasPermission(Permission.PrimaryJournal.PRIMARY_JOURNAL_DETAIL) ? (
              <Redirect
                url={EvidenceBrowserUrl.PRIMARY_JOURNAL}
                id={primaryJournalId}
              />
            ) : undefined
          }
        />
        <FormTextField
          name="parent.construction.siteId"
          label={
            <FormattedMessage
              id="ESD__PARTIAL_JOURAL__DETAIL__FIELD_LABEL__PARENT_CONSTRUCTION_ID"
              defaultMessage="Číslo stavby"
            />
          }
          disabled={isExisting && source.url === EvidenceApiUrl.PARTIAL_JOURNAL}
        />

        {/*   <FormAutocomplete
          name="parent.investor"
          source={investors}
          label={
            <FormattedMessage
              id="ESD__PARTIAL_JOURAL__DETAIL__FIELD_LABEL__PARENT_SUPERVISER"
              defaultMessage="Investor"
            />
          }
          disabled
        /> */}
        <FormAutocomplete
          name="parent.supervisor"
          source={persons}
          label={
            <FormattedMessage
              id="ESD__PARTIAL_JOURAL__DETAIL__FIELD_LABEL__PARENT_SUPERVISER"
              defaultMessage="Technický dozor vlastníka"
            />
          }
          labelMapper={personLabelMapper}
          disabled
        />
        <FormAutocomplete
          name="parent.planner"
          source={persons}
          label={
            <FormattedMessage
              id="ESD__PARTIAL_JOURAL__DETAIL__FIELD_LABEL__PARENT_PLANNER"
              defaultMessage="Zodpovedný projektant"
            />
          }
          labelMapper={personLabelMapper}
          disabled
        />
        {/*   <FormAutocomplete
          name="parent.supplier"
          source={suppliers}
          label={
            <FormattedMessage
              id="ESD__PARTIAL_JOURAL__DETAIL__FIELD_LABEL__PARENT_SUPERVISER"
              defaultMessage="Zhotovitel"
            />
          }
          disabled
        /> */}
        <FormAutocomplete
          name="parent.leader"
          source={persons}
          label={
            <FormattedMessage
              id="ESD__PARTIAL_JOURAL__DETAIL__FIELD_LABEL__PARENT_LEADER"
              defaultMessage="Hlavný stavbyvedúci"
            />
          }
          labelMapper={personLabelMapper}
          disabled
        />
        {/*   <FormAutocomplete
          name="parent.?"
          source={?}
          label={
            <FormattedMessage
              id="ESD__PARTIAL_JOURAL__DETAIL__FIELD_LABEL__PARENT_SUPERVISER"
              defaultMessage="Projektový manažér / manažér prevádzky"
            />
          }
          disabled
        /> */}
        {/*   <FormAutocomplete
          name="parent.?"
          source={?}
          label={
            <FormattedMessage
              id="ESD__PARTIAL_JOURAL__DETAIL__FIELD_LABEL__PARENT_SUPERVISER"
              defaultMessage="Hlavný vedúci prác / vedúci skupiny"
            />
          }
          disabled
        /> */}
        <FormTextField
          name="parent.team"
          label={
            <FormattedMessage
              id="ESD__PARTIAL_JOURAL__DETAIL__FIELD_LABEL__PARENT_TEAM"
              defaultMessage="Tím zhotoviteľa"
            />
          }
          disabled
        />
        <FormSelect
          name="parent.state"
          source={primaryStates}
          label={
            <FormattedMessage
              id="ESD__PARTIAL_JOURAL__DETAIL__FIELD_LABEL__PARENT_STATE"
              defaultMessage="Stav hlavného denníka"
            />
          }
          disabled
          valueIsId
        />
      </FormPanel>

      <FormPanel
        label={
          <FormattedMessage
            id="ESD__PARTIAL_JOURAL__DETAIL__PANEL_TITLE__PARENT_ADDRESS"
            defaultMessage="Lokalita"
          />
        }
        defaultExpanded={false}
        expandable
      >
        <FormTextField
          name="parent.address.municipality"
          label={
            <FormattedMessage
              id="ESD__PARTIAL_JOURAL__DETAIL__FIELD_LABEL__PARENT_MUNICIPALITY"
              defaultMessage="Obec"
            />
          }
          disabled
        />
        <FormTextField
          name="parent.address.region"
          label={
            <FormattedMessage
              id="ESD__PARTIAL_JOURAL__DETAIL__FIELD_LABEL__PARENT_REGION"
              defaultMessage="Región"
            />
          }
          disabled
        />
        <FormTextField
          name="parent.address.coordinates.latitude"
          label={
            <FormattedMessage
              id="ESD__PARTIAL_JOURAL__DETAIL__FIELD_LABEL__PARENT_LATITUDE"
              defaultMessage="Zemepisná šírka"
            />
          }
          disabled
        />
        <FormTextField
          name="parent.address.coordinates.longitude"
          label={
            <FormattedMessage
              id="ESD__PARTIAL_JOURAL__DETAIL__FIELD_LABEL__PARENT_LOGITUDE"
              defaultMessage="Zemepisná dĺžka"
            />
          }
          disabled
        />
      </FormPanel>

      <PartialJournalBasicFields />

      {hasJournalEntryPermission && (
        <JournalEntryField
          source={journalEntrySource}
          currentRef={{ id: journalId, journalType: JournalTypeEnum.PARTIAL }}
          disabled={
            mode !== DetailMode.VIEW ||
            isLocked ||
            !hasPermission(Permission.JournalEntry.JOURNAL_ENTRY_CREATE)
          }
        />
      )}

      <FormPanel
        label={
          <FormattedMessage
            id="ESD__PARTIAL_JOURAL__DETAIL__PANEL_TITLE__GENERAL"
            defaultMessage="Všeobecné"
          />
        }
        expandable
      >
        <FormLocalDateTimeField
          name="lastEntry"
          label={
            <FormattedMessage
              id="ESD__PARTIAL_JOURAL__DETAIL__FIELD_LABEL__LAST_ENTRY"
              defaultMessage="Posledný denný záznam denníka"
            />
          }
          disabled
        />
        <FormLocalDateTimeField
          name="closed"
          label={
            <FormattedMessage
              id="ESD__PARTIAL_JOURAL__DETAIL__FIELD_LABEL__CLOSED"
              defaultMessage="Uzavretie denníka"
            />
          }
          disabled
        />
        <FormLocalDateTimeField
          name="cancelled"
          label={
            <FormattedMessage
              id="ESD__PARTIAL_JOURAL__DETAIL__FIELD_LABEL__CANCELLED"
              defaultMessage="Zrušenie denníka"
            />
          }
          disabled
        />
        <FormTextField
          name="cancelledReason"
          label={
            <FormattedMessage
              id="ESD__PARTIAL_JOURAL__DETAIL__FIELD_LABEL__CANCELLED_REASON"
              defaultMessage="Dôvod zrušenia denníka"
            />
          }
          disabled
        />
        <FormLocalDateTimeField
          name="created"
          label={
            <FormattedMessage
              id="ESD__PARTIAL_JOURAL__DETAIL__FIELD_LABEL__CREATED"
              defaultMessage="Vytvorenie denníka"
            />
          }
          disabled
        />
        <FormTextField
          name="createdBy.name"
          label={
            <FormattedMessage
              id="ESD__PARTIAL_JOURAL__DETAIL__FIELD_LABEL__CREATED_BY"
              defaultMessage="Autor vytvorenia denníka"
            />
          }
          disabled
        />
        <FormLocalDateTimeField
          name="updated"
          label={
            <FormattedMessage
              id="ESD__PARTIAL_JOURAL__DETAIL__FIELD_LABEL__UPDATED"
              defaultMessage="Posledná úprava denníka"
            />
          }
          disabled
        />
        <FormTextField
          name="updatedBy.name"
          label={
            <FormattedMessage
              id="ESD__PARTIAL_JOURAL__DETAIL__FIELD_LABEL__UPDATED_BY"
              defaultMessage="Autor poslednej úpravy denníka"
            />
          }
          disabled
        />
        <FormAutocomplete
          name="parent.tenant"
          source={tenants}
          label={
            <FormattedMessage
              id="ESD__PARTIAL_JOURAL__DETAIL__FIELD_LABEL__PARENT_TENANT"
              defaultMessage="Vlastník stavebného denníka"
            />
          }
          disabled
        />
      </FormPanel>
    </>
  );
}

export function PartialJournalBasicFields() {
  const { setFieldValue } = useContext(FormContext);
  const { isExisting, source } = useContext(DetailContext);
  const { supplier, leader, deputyLeader, finalReport } = useFormSelector(
    (values: PartialJournal) => ({
      supplier: values.supplier,
      leader: values.leader,
      deputyLeader: values.deputyLeader,
      finalReport: values.finalReport,
    })
  );

  const types = usePartialJournalTypes();
  const states = usePartialJournalStates();
  const suppliers = useSuppliers();

  // With this filter the public autocomplete returns assigned persons
  // to a particular supplier. It has to be used, when the supplier is set is partial Journal
  const supplierPersons = useAllPersons({
    filters: [
      {
        value: supplier?.id,
        field: 'suppliers.id',
        operation: ApiFilterOperation.EQ,
      },
    ],
  });
  const allPersons = useAllPersons();

  return (
    <FormPanel
      label={
        <FormattedMessage
          id="ESD__PARTIAL_JOURAL__DETAIL__PANEL_TITLE__OVERVIEW"
          defaultMessage="Základné údaje dielčieho denníka"
        />
      }
    >
      <FormTextField
        name="parent.construction.name"
        label={
          <FormattedMessage
            id="ESD__PARTIAL_JOURAL__DETAIL__FIELD_LABEL__NAME"
            defaultMessage="Názov stavby"
          />
        }
        disabled
      />
      <FormTextField
        name="parent.construction.siteId"
        label={
          <FormattedMessage
            id="ESD__PARTIAL_JOURAL__DETAIL__FIELD_LABEL__CONSTRUCTION_SITE_ID"
            defaultMessage="Číslo stavby"
          />
        }
        disabled
      />
      <FormSelect
        name="type"
        source={types}
        label={
          <FormattedMessage
            id="ESD__PARTIAL_JOURAL__DETAIL__FIELD_LABEL__TYPE"
            defaultMessage="Typ dielčieho denníka"
          />
        }
        valueIsId
        required
      />
      <FormAutocomplete
        name="supplier"
        source={suppliers}
        label={
          <FormattedMessage
            id="ESD__PARTIAL_JOURAL__DETAIL__FIELD_LABEL__SUPPLIER"
            defaultMessage="Dodávateľ"
          />
        }
        notifyChange={() => {
          if (leader || deputyLeader) {
            setFieldValue('leader', null);
            setFieldValue('deputyLeader', null);
          }
        }}
        disabled={isExisting && source.url === EvidenceApiUrl.PARTIAL_JOURNAL}
      />
      <FormAutocomplete
        name="leader"
        source={supplier ? supplierPersons : allPersons}
        label={
          <FormattedMessage
            id="ESD__PARTIAL_JOURAL__DETAIL__FIELD_LABEL__LEADER"
            defaultMessage="Stavbyvedúci dodávateľa"
          />
        }
        labelMapper={personLabelMapper}
        required
      />

      <FormAutocomplete
        name="deputyLeader"
        source={supplier ? supplierPersons : allPersons}
        label={
          <FormattedMessage
            id="ESD__PARTIAL_JOURAL__DETAIL__FIELD_LABEL__DEPUTY_LEADER"
            defaultMessage="Vedúci prác dodávateľa"
          />
        }
        labelMapper={personLabelMapper}
      />
      {/*   <FormAutocomplete
          name="parent.supplier"
          source={suppliers}
          label={
            <FormattedMessage
              id="ESD__PARTIAL_JOURAL__DETAIL__FIELD_LABEL__PARENT_SUPERVISER"
              defaultMessage="Tím zhotovitela"
            />
          }
          disabled
        /> */}
      {
        // VERZE dílčího deníku?
      }
      <FormSelect
        name="state"
        source={states}
        label={
          <FormattedMessage
            id="ESD__PARTIAL_JOURAL__DETAIL__FIELD_LABEL__STATE"
            defaultMessage="Stav dielčieho denníka"
          />
        }
        valueIsId
        disabled
      />
      {finalReport && (
        <FormFileField
          name="finalReport"
          label={
            <FormattedMessage
              id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL__FINAL_REPORT"
              defaultMessage="Záverečná správa dielčieho denníka"
            />
          }
          disabled
        />
      )}
    </FormPanel>
  );
}
