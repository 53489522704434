import { useIntl } from 'react-intl';
import { ApiFilterOperation, FieldFilter } from '@eas/common-web';
import { useSupplierMechanismCategories } from '@modules/dict__mechanism-category/mechanism-category-api';

export const useFilterFields = () => {
  const intl = useIntl();

  return [
    {
      label: intl.formatMessage({
        id: 'ZSD__SUPPLIER_MECHANISMS__TABLE__COLUMN_FILTER__CATEGORY',
        defaultMessage: 'Typ mechanizmu',
      }),
      datakey: 'category',
      filterkey: 'category.id',
      defaultOperation: ApiFilterOperation.EQ,
      FilterComponent: FieldFilter.FilterAutocompleteCell(
        useSupplierMechanismCategories
      ),
    },
    {
      label: intl.formatMessage({
        id: 'ZSD__SUPPLIER_MECHANISMS__TABLE__COLUMN_FILTER__LICENCE_PLATE',
        defaultMessage: 'ŠPZ',
      }),
      datakey: 'licencePlate',
      filterkey: 'licencePlate',
      defaultOperation: ApiFilterOperation.EQ,
      FilterComponent: FieldFilter.FilterTextCell,
    },
  ];
};
