/**
 * Primary journal permissions
 */
export enum PermissionPrimaryJournal {
  /**
   * Prehľad hlavných denníkov
   */
  PRIMARY_JOURNAL_LIST = 'PRIMARY_JOURNAL_LIST',

  /**
   * Prehľad priradených hlavných denníkov
   */
  PRIMARY_JOURNAL_READ_ASSIGNED = 'PRIMARY_JOURNAL_READ_ASSIGNED',

  /**
   * Detail hlavného denníka
   */
  PRIMARY_JOURNAL_DETAIL = 'PRIMARY_JOURNAL_DETAIL',

  /**
   * Vytvorenie hlavného denníka
   */
  PRIMARY_JOURNAL_CREATE = 'PRIMARY_JOURNAL_CREATE',

  /**
   * Editácia hlavného denníka
   */
  PRIMARY_JOURNAL_UPDATE = 'PRIMARY_JOURNAL_UPDATE',

  /**
   * Zmazanie hlavného denníka
   */
  PRIMARY_JOURNAL_DELETE = 'PRIMARY_JOURNAL_DELETE',

  /**
   * Zatvorenie hlavného denníka
   */
  PRIMARY_JOURNAL_CLOSE = 'PRIMARY_JOURNAL_CLOSE',

  /**
   * Zrušenie hlavného denníka - přímé (bez notifikace)
   */
  PRIMARY_JOURNAL_CANCEL = 'PRIMARY_JOURNAL_CANCEL',

  /**
   * Podpísanie hlavného denníka
   */
  PRIMARY_JOURNAL_SIGN = 'PRIMARY_JOURNAL_SIGN',

  /**
   * Znovuotvorenie hlavného denníka
   */
  PRIMARY_JOURNAL_REOPEN = 'PRIMARY_JOURNAL_REOPEN',
}
