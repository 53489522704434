import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  AuthoredEvidence,
  DictionaryEvidence,
  FormAutocomplete,
  FormTextField,
} from '@eas/common-web';
import { useTenants } from '@modules/tenant/tenant-api';

export const Fields = () => {
  const tenants = useTenants();

  return (
    <>
      <DictionaryEvidence.FieldOrder />
      <FormAutocomplete
        name="tenant"
        source={tenants}
        label={
          <FormattedMessage
            id="ESD__MECHANISMS__DETAIL__FIELD_LABEL__TENANT"
            defaultMessage="Vlastník"
          />
        }
        disabled
      />
      <FormTextField
        name="name"
        label={
          <FormattedMessage
            id="ESD__TBPP__DETAIL__FIELD_LABEL__EXTERNAL_ID"
            defaultMessage="Názov TBPP"
          />
        }
        required
      />

      <FormTextField
        name="code"
        label={
          <FormattedMessage
            id="ESD__TBPP__DETAIL__FIELD_LABEL__CODE"
            defaultMessage="Číslo TBPP"
          />
        }
      />
      <FormTextField
        name="externalId"
        label={
          <FormattedMessage
            id="ESD__TBPP__DETAIL__FIELD_LABEL__EXTERNAL_ID"
            defaultMessage="Externý identifikátor"
          />
        }
      />
      <DictionaryEvidence.FieldActive />
      <AuthoredEvidence.AuthoredFields />
    </>
  );
};
