import { useIntl } from 'react-intl';
import {
  DomainObject,
  TableFieldCells,
  TableFieldColumn,
} from '@eas/common-web';
import { useProfessions } from '@modules/dict__profession/profession-api';
import { usePersons } from '@modules/person/person-api';
import { personLabelMapper } from '@utils/label-mapper';

export function useColumns<T extends DomainObject>(): TableFieldColumn<T>[] {
  const intl = useIntl();

  return [
    {
      datakey: 'person',
      name: intl.formatMessage({
        id: 'ESD__PERSON_COLLECTION__TABLE__COLUMN__NAME',
        defaultMessage: 'Osoba',
      }),
      width: 250,
      CellComponent: TableFieldCells.useAutocompleteCellFactory({
        dataHook: usePersons,
        multiple: false,
        labelMapper: personLabelMapper,
      }),
    },
    {
      datakey: 'person.profession',
      name: intl.formatMessage({
        id: 'ESD__PERSON_COLLECTION__TABLE__COLUMN__PROFESSION',
        defaultMessage: 'Pracovná pozícia',
      }),
      width: 200,
      CellComponent: TableFieldCells.useAutocompleteCellFactory({
        dataHook: useProfessions,
        multiple: false,
      }),
    },
  ];
}
