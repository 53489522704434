import React from 'react';
import SignaturesTableField from '@composite/signatures-field/signatures-table-field';

export function PartialJournalSignaturesFields() {
  return (
    <>
      <SignaturesTableField />
    </>
  );
}
