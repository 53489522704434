import React, { useEffect } from 'react';
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DetailContext,
  DetailHandle,
  DetailMode,
  EvidenceContext,
  SnackbarContext,
  SnackbarVariant,
  UserContext,
} from '@eas/common-web';
import { useValidationSchema as useProfessionSchema } from '@modules/dict__profession/profession-schema';
import { SUPPLIER_PROFESSION_DICTIONARY } from '@modules/suppliers/supplier-hooked-sources';
import {
  SupplierDictionaryActionBar,
  SupplierDictionaryField,
} from '@composite/supplier-dictionary-field';
import { Me, Profession, Supplier } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';

export function SupplierProfessionsFields() {
  const { user, hasPermission } = useContext<UserContext<Me>>(UserContext);
  const {
    source: { data },
    mode,
  } = useContext<DetailHandle<Supplier>>(DetailContext);

  const { hookedSources } = useContext(EvidenceContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const supplierId = data?.id || '';
  const source = hookedSources[SUPPLIER_PROFESSION_DICTIONARY]?.source;
  const disabled =
    mode !== DetailMode.VIEW || supplierId !== user?.supplier?.id;

  const hasMissingPermissions = !hasPermission(
    Permission.Profession.PROFESSION_LIST_SUPPLIER
  );

  useEffect(() => {
    if (hasMissingPermissions) {
      showSnackbar(
        <FormattedMessage
          id="ESD__SUPPLIER__DETAIL__ABSENCE_UNAVAILABLE__SNACKBAR"
          defaultMessage="Chýbajúce oprávnenia - Pre zobrazenie hodnôt dodávateľského číselníka pracovných pozícií nemáte dostatočné oprávnenia"
        />,
        SnackbarVariant.WARNING,
        true
      );
    }
  }, [hasMissingPermissions, showSnackbar]);

  return hasMissingPermissions ? (
    <></>
  ) : (
    <>
      {data && (
        <SupplierDictionaryActionBar
          importSourceUrls={[EvidenceApiUrl.PROFESSION]}
          takeoverSourceUrls={[EvidenceApiUrl.PROFESSION]}
        />
      )}
      <SupplierDictionaryField<Profession>
        supplierId={supplierId}
        title={
          <FormattedMessage
            id="ESD__SUPPLIER__DETAIL__FIELD_LABEL__PROFESSION__TITLE"
            defaultMessage="Dodávateľský číselník - Pracovné pozície"
          />
        }
        sourceUrl={EvidenceApiUrl.PROFESSION}
        source={source}
        disabled={disabled}
        useSchema={useProfessionSchema}
      />
    </>
  );
}
