/**
 * Typ notifikace
 */
export enum NotificationType {
  /**
   * Emailová notifikace
   */
  EMAIL = 'EMAIL',

  /**
   * Aplikační notifikace
   */
  APPLICATION = 'APPLICATION',
}

/**
 *  Událost
 */
export enum NotificationEventType {
  /**
   * žádost o zrušení hlavného denníka
   */
  PRIMARY_JOURNAL_CANCELLATION_REQUESTED = 'PRIMARY_JOURNAL_CANCELLATION_REQUESTED',
  /**
   * ŽÁdost o zrušení dílčieho denníka
   */
  PARTIAL_JOURNAL_CANCELLATION_REQUESTED = 'PARTIAL_JOURNAL_CANCELLATION_REQUESTED',
}
