import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DetailActionButton,
  SecondaryDetailActionbarButton,
} from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';
import { useSupplierDictionariesTakeover } from '../dialog-actions/supplier-dictionary-takeover-hook';

interface SupplierDictionaryTakeoverActionButtonsProps {
  takeoverSourceUrls: EvidenceApiUrl[];
  disableEdit: boolean;
}

export function SupplierDictionaryTakeoverActionButtons({
  takeoverSourceUrls,
  disableEdit,
}: SupplierDictionaryTakeoverActionButtonsProps) {
  const { takeoverInfo } = useSupplierDictionariesTakeover(takeoverSourceUrls);

  return (
    <>
      {Object.entries(takeoverInfo).map(([key, { callApi, showButton }]) =>
        showButton ? (
          <DetailActionButton
            key={`${key}_TAKEOVER`}
            promptKey={`${key}_TAKEOVER`}
            buttonLabel={
              <FormattedMessage
                id={`ESD__SUPPLIER__DETAIL__DICTIONARY_TAB__TAKEOVER__BUTTON_LABEL__${key}`}
                defaultMessage="Prevziať"
              />
            }
            dialogTitle={
              <FormattedMessage
                id={`ESD__SUPPLIER__DETAIL__DICTIONARY_TAB__TAKEOVER__DIALOG_TITLE__${key}`}
                defaultMessage="Upozornenie"
              />
            }
            dialogText={
              <FormattedMessage
                id={`ESD__SUPPLIER__DETAIL__DICTIONARY_TAB__TAKEOVER__DIALOG_TEXT__${key}`}
                defaultMessage="Obsah zdieľaného číselníka bude skopírovaný do dodávateľského. Duplicitné záznamy sa vytvárať nebudú."
              />
            }
            apiCall={callApi}
            ButtonComponent={SecondaryDetailActionbarButton}
            buttonDisabled={disableEdit}
          />
        ) : (
          <></>
        )
      )}
    </>
  );
}
