import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import {
  FilesProvider,
  FormAnchorProvider,
  LocaleName,
  LocaleProvider,
  NavigationProvider,
  PromptProvider,
  SnackbarProvider,
  UserProvider,
  ValidationProvider,
  WebsocketProvider,
  useEventCallback,
} from '@eas/common-web';
import { AuthConfigProvider } from '@components/auth/auth-config-provider';
import { Me } from '@models';
import { AppPublic } from './app-public';
import { AppSecure } from './app-secure';
import { EvidenceBrowserUrl } from './enums/evidence-url';
import sk from './lang/sk.json';
import { ThemeProvider } from './modules/theme/theme-provider';

class ErrorBoundary extends React.Component {
  state = { error: null };

  render() {
    if (!this.state.error) {
      return this.props.children;
    } else {
      console.log(this.state.error);

      return (
        <div>
          <h1>Chyba aplikace</h1>
          <p>
            Kontaktujte podporu... zatím:{' '}
            <a href="https://trex-runner.com/">https://trex-runner.com/</a>
          </p>
          <p>{(this.state.error as any)?.toString()}</p>
        </div>
      );
    }
  }

  static getDerivedStateFromError(error: any) {
    return { error };
  }
}

export function App() {
  const checkPermission = useEventCallback((user: Me, permission: string) => {
    /*    if (user.email === 'power@user.com') {
      return true;
    } */

    return !!user.authorities.find((auth) => auth.authority === permission);
  });
  return (
    <ErrorBoundary>
      <ThemeProvider>
        <LocaleProvider defaultLocale={LocaleName.sk} messages={{ SLOVAK: sk }}>
          <ValidationProvider>
            <SnackbarProvider timeout={3000}>
              <FilesProvider url="/api/esd/file">
                <FormAnchorProvider>
                  <PromptProvider>
                    <BrowserRouter>
                      <NavigationProvider>
                        <AuthConfigProvider>
                          <UserProvider
                            meUrl="/api/esd/context"
                            logoutUrl="/api/esd/logout"
                            checkPermission={checkPermission}
                          >
                            <Switch>
                              <Route exact path={EvidenceBrowserUrl.LOGIN}>
                                <AppPublic />
                              </Route>
                              <Route path="/">
                                <WebsocketProvider
                                  wsUrl="/api/esd/stomp"
                                  destinations={[
                                    '/user/queue/session',
                                    '/user/queue/notification',
                                  ]}
                                  debug={false}
                                  requiredUser={false}
                                >
                                  <AppSecure />
                                </WebsocketProvider>
                              </Route>
                            </Switch>
                          </UserProvider>
                        </AuthConfigProvider>
                      </NavigationProvider>
                    </BrowserRouter>
                  </PromptProvider>
                </FormAnchorProvider>
              </FilesProvider>
            </SnackbarProvider>
          </ValidationProvider>
        </LocaleProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}
