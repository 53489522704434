import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import {
  DetailActionButton,
  FormTextField,
  PrimaryDetailActionbarButton,
  SecondaryDetailActionbarButton,
  SnackbarContext,
  SnackbarVariant,
} from '@eas/common-web';
import { useSignJournalByKepDialog } from '@components/kep-signature/sign-partial-journal-kep-hook';
import { ErrorObject } from '@models';
import { JournalTypeEnum, Messages } from '@enums';
import { useCancelPrimaryJournalDialog } from './dialog-actions/cancel-primary-journal-hook';
import { useClosePrimaryDialog } from './dialog-actions/close-primary-hook';
import { useReopenPrimaryJournalDialog } from './dialog-actions/reopen-primary-journal-hook';
import { useSignPrimaryJournalByTimestamp } from './dialog-actions/sign-timestamp-hook';

export function PrimaryJournalActionbar() {
  const { showSnackbar } = useContext(SnackbarContext);

  const { callApi: closeApiCall, showButton: showCloseButton } =
    useClosePrimaryDialog();

  const {
    callApi: signByTimestampApiCall,
    showButton: showSignByTimeStampButton,
  } = useSignPrimaryJournalByTimestamp();

  const { callApi: signByKepCallback, showButton: showSignByKepButton } =
    useSignJournalByKepDialog(JournalTypeEnum.PRIMARY);

  const { callApi: reopenApiCall, showButton: showReopenButton } =
    useReopenPrimaryJournalDialog();

  const {
    callApi: cancelApiCall,
    showButton: showCancelButton,
    isDirectCancel,
  } = useCancelPrimaryJournalDialog();

  return (
    <>
      {showCloseButton && (
        <DetailActionButton
          promptKey="CLOSE_PRIMARY"
          buttonLabel={
            <FormattedMessage
              id="ESD__PRIMARY_JOURNAL__ACTIONBAR__CLOSE"
              defaultMessage="Uzavrieť denník"
            />
          }
          dialogText={
            <FormattedMessage
              id="ESD__PRIMARY_JOURNAL__ACTIONBAR__CLOSE_DIALOG_TEXT"
              defaultMessage="Skutočne chcete uzavrieť hlavný denník?"
            />
          }
          dialogTitle={
            <FormattedMessage
              id="ESD__PRIMARY_JOURNAL__ACTIONBAR__CLOSE_DIALOG_TITLE"
              defaultMessage="Upozornenie"
            />
          }
          apiCall={closeApiCall}
          ButtonComponent={SecondaryDetailActionbarButton}
          onError={async (err: ErrorObject<string>) =>
            showSnackbar(
              ...(err.code && err.code in Messages.PrimaryJournal.CLOSE
                ? Messages.PrimaryJournal.CLOSE[err.code]
                : Messages.Common.UNEXPECTED_ERROR)
            )
          }
        />
      )}
      {showSignByTimeStampButton && (
        <DetailActionButton
          promptKey="SIGN_PRIMARY_TIMESTAMP"
          buttonLabel={
            <FormattedMessage
              id="ESD__PRIMARY_JOURNAL__ACTIONBAR__SIGN"
              defaultMessage="Podpísať denník (časová pečiatka)"
            />
          }
          dialogText={
            <FormattedMessage
              id="ESD__PRIMARY_JOURNAL__ACTIONBAR__SIGN_DIALOG_TEXT"
              defaultMessage="Skutočne chcete podpísať hlavný denník?"
            />
          }
          dialogTitle={
            <FormattedMessage
              id="ESD__PRIMARY_JOURNAL__ACTIONBAR__SIGN_DIALOG_TITLE"
              defaultMessage="Upozornenie"
            />
          }
          ButtonComponent={SecondaryDetailActionbarButton}
          isLocked={false}
          apiCall={signByTimestampApiCall}
        />
      )}
      {showSignByKepButton && (
        <DetailActionButton
          promptKey="SIGN_PRIMARY"
          buttonLabel={
            <FormattedMessage
              id="ESD__PRIMARY_JOURNAL__ACTIONBAR__SIGN"
              defaultMessage="In Development (KEP)"
            />
          }
          dialogText={
            <FormattedMessage
              id="ESD__PRIMARY_JOURNAL__ACTIONBAR__SIGN_DIALOG_TEXT"
              defaultMessage="Skutočne chcete podpísať hlavný denník? Budete presmerovaný na podpisovací portál."
            />
          }
          dialogTitle={
            <FormattedMessage
              id="ESD__PRIMARY_JOURNAL__ACTIONBAR__SIGN_DIALOG_TITLE"
              defaultMessage="Upozornenie"
            />
          }
          ButtonComponent={SecondaryDetailActionbarButton}
          isLocked={false}
          submitCallback={signByKepCallback}
        />
      )}
      {showReopenButton && (
        <DetailActionButton
          promptKey="REOPEN_PARTIAL"
          buttonLabel={
            <FormattedMessage
              id="ESD_PARTIAL_JOURNAL__ACTION_BAR__REOPEN__BUTTON"
              defaultMessage="Otvoriť denník"
            />
          }
          dialogText={
            <FormattedMessage
              id="ESD_PARTIAL_JOURNAL__ACTION_BAR__REOPEN__DIALOG_TEXT"
              defaultMessage="Skutočne chcete znovu otvoriť hlavný denník?"
            />
          }
          dialogTitle={
            <FormattedMessage
              id="ESD_PARTIAL_JOURNAL__ACTION_BAR__REOPEN__DIALOG_TITLE"
              defaultMessage="Upozornenie"
            />
          }
          apiCall={reopenApiCall}
          ButtonComponent={SecondaryDetailActionbarButton}
          FormFields={() => (
            <FormTextField name="reason" label="Dôvod" required />
          )}
          formValidationSchema={Yup.object().shape({
            reason: Yup.string().nullable().required(),
          })}
          isLocked={false}
          onError={async (err: ErrorObject<string>) =>
            showSnackbar(
              ...(err.code && err.code in Messages.PrimaryJournal.REOPEN
                ? Messages.PrimaryJournal.REOPEN[err.code]
                : Messages.Common.UNEXPECTED_ERROR)
            )
          }
        />
      )}
      {showCancelButton && (
        <DetailActionButton
          promptKey="CANCEL_PRIMARY"
          buttonLabel={
            <FormattedMessage
              id="ESD_PRIMARY_JOURNAL__ACTION_BAR__CANCEL__BUTTON"
              defaultMessage="Zrušiť denník"
            />
          }
          dialogText={
            isDirectCancel ? (
              <FormattedMessage
                id="ESD_PRIMARY_JOURNAL__ACTION_BAR__CANCEL__DIALOG_TEXT"
                defaultMessage="Po potvrdení dôjde k zrušení denníku."
              />
            ) : (
              <FormattedMessage
                id="ESD_PRIMARY_JOURNAL__ACTION_BAR__CANCEL_NOTIFICATION__DIALOG_TEXT"
                defaultMessage="Odošle sa notifikácia administrátorovi, ktorý musí zrušenie potvrdiť. Uveďte dôvod na zrušenie."
              />
            )
          }
          dialogTitle={
            <FormattedMessage
              id="ESD_PRIMARY_JOURNAL__ACTION_BAR__CANCEL__DIALOG_TITLE"
              defaultMessage="Upozornenie"
            />
          }
          apiCall={cancelApiCall}
          ButtonComponent={PrimaryDetailActionbarButton}
          isLocked={false}
          FormFields={
            isDirectCancel
              ? undefined
              : () => <FormTextField name="reason" label="Dôvod" required />
          }
          onSuccess={
            isDirectCancel
              ? undefined
              : async () => {
                  showSnackbar(
                    'Žiadosť o zrušenie bola odoslaná administrátorovi.',
                    SnackbarVariant.SUCCESS
                  );
                }
          }
          formValidationSchema={
            isDirectCancel
              ? undefined
              : Yup.object().shape({
                  reason: Yup.string().nullable().required(),
                })
          }
          /*  onError={async (err: ErrorObject<string>) =>
            showSnackbar(
              ...(err.code && err.code in Messages.PrimaryJournal.REOPEN
                ? Messages.PrimaryJournal.REOPEN[err.code]
                : Messages.Common.UNEXPECTED_ERROR)
            )
          } */
        />
      )}
    </>
  );
}
