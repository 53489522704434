import { useContext, useRef } from 'react';
import {
  AbortableFetch,
  DetailContext,
  DetailHandle,
  SnackbarContext,
  SnackbarVariant,
  abortableFetch,
} from '@eas/common-web';
import { PrimaryJournal } from '@models';
import { EvidenceApiUrl, JournalTypeEnum, PrimaryJournalState } from '@enums';
import { useUserAssignments } from '@utils/use-user-assignments';

// Keys used for kep local storage.
export const KEP_PARTIAL_JOURNAL_ID = 'KEP_PARTIAL_JOURNAL_ID';
export const KEP_PRIMARY_JOURNAL_ID = 'KEP_PRIMARY_JOURNAL_ID';

export function useSignJournalByKepDialog(type: JournalTypeEnum) {
  const {
    source: { data, setLoading },
  } = useContext<DetailHandle<PrimaryJournal>>(DetailContext);
  const { showSnackbar } = useContext(SnackbarContext);
  //const { isPrimaryJournalAssigned } = useUserAssignments();

  const fetch = useRef<AbortableFetch | null>(null);

  const isPrimaryJournal = type === JournalTypeEnum.PRIMARY;

  const callApi = async () => {
    try {
      if (fetch.current !== null) {
        fetch.current.abort();
      }
      setLoading(true);

      fetch.current = abortableFetch(
        `${
          isPrimaryJournal
            ? EvidenceApiUrl.PRIMARY_JOURNAL
            : EvidenceApiUrl.PARTIAL_JOURNAL
        }/${data?.id}/sign/kep/init`,
        {
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
          method: 'POST',
          body: JSON.stringify({
            returnUrlTemplate: `${window.location.origin}/disigcallback.aspx?status=@(status)&sessionId=@(sessionId)`,
          }),
        }
      );

      // Response is a URL of the external signing website.
      const response = await fetch.current.text();

      setLoading(false);

      if (response && data?.id) {
        // Journal ID is saved to local storage here (when KEP signing is initialized), so we can then in app-secure.tsx check, if we have this ID saved.
        // If yes, we then redirect to exact journal (primary or partial), where the KEP signing is finished by the next api call ( ../finalize ).
        localStorage.setItem(
          isPrimaryJournal ? KEP_PRIMARY_JOURNAL_ID : KEP_PARTIAL_JOURNAL_ID,
          data?.id
        );

        window.location.href = response;
      }
    } catch {
      setLoading(false);

      showSnackbar(
        'Pri inicializácii podpisu metódou KEP sa niečo pokazilo',
        SnackbarVariant.ERROR
      );
    }
  };

  const showButton = data?.state === PrimaryJournalState.CLOSED; // Same state for Primary and Partial journal
  // && isPrimaryJournalAssigned(data?.id);

  return { callApi, showButton };
}
