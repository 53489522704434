import { useIntl } from 'react-intl';
import { ApiFilterOperation, FieldFilter } from '@eas/common-web';
import { useSupplierWorkCategories } from '@modules/dict__work-category/work-category-api';

export const useFilterFields = () => {
  const intl = useIntl();

  return [
    {
      label: intl.formatMessage({
        id: 'ZSD__SUPPLIER_WORK__TABLE__COLUMN_FILTER__CATEGORY',
        defaultMessage: 'Typ vedenia',
      }),
      datakey: 'category',
      filterkey: 'category.id',
      defaultOperation: ApiFilterOperation.EQ,
      FilterComponent: FieldFilter.FilterAutocompleteCell(
        useSupplierWorkCategories
      ),
    },
  ];
};
