import { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  DomainObject,
  TableFieldCells,
  TableFieldColumn,
  UserContext,
} from '@eas/common-web';
import { useWorks } from '@modules/dict__work/work-api';
import { Me } from '@models';
import { useAssemblyTypes } from './work-done-api';

export function useColumns<T extends DomainObject>(): TableFieldColumn<T>[] {
  const { user } = useContext<UserContext<Me>>(UserContext);
  const intl = useIntl();

  const showTBPP = user?.tenant?.code !== 'ZSD';

  return [
    {
      datakey: 'type.category.name',
      name: intl.formatMessage({
        id: 'ESD__WORK_DONE_COLLECTION__TABLE__COLUMN__CATEGORY',
        defaultMessage: 'Kategória práce',
      }),
      width: 200,
    },
    {
      datakey: 'type',
      name: intl.formatMessage({
        id: 'ESD__WORK_DONE_COLLECTION__TABLE__COLUMN__TYPE',
        defaultMessage: 'Práca',
      }),
      width: 200,
      CellComponent: TableFieldCells.useAutocompleteCellFactory({
        dataHook: useWorks,
        multiple: false,
      }),
    },
    {
      datakey: 'assemblyType',
      name: intl.formatMessage({
        id: 'ESD__WORK_DONE_COLLECTION__TABLE__COLUMN__ASSEMBLY_TYPE',
        defaultMessage: 'Druh práce',
      }),
      width: 100,
      CellComponent: TableFieldCells.useSelectCellFactory(useAssemblyTypes),
    },
    {
      datakey: 'type.units',
      name: intl.formatMessage({
        id: 'ESD__WORK_DONE_COLLECTION__TABLE__COLUMN__WORK_UNITS',
        defaultMessage: 'Merná jednotka',
      }),
      width: 150,
    },
    {
      datakey: 'amount',
      name: intl.formatMessage({
        id: 'ESD__WORK_DONE_COLLECTION__TABLE__COLUMN__AMOUNT',
        defaultMessage: 'Počet vykonanej práce za daný deň',
      }),
      width: 80,
    },
    {
      datakey: 'section',
      name: intl.formatMessage({
        id: 'ESD__WORK_DONE_COLLECTION__TABLE__COLUMN__SECTION',
        defaultMessage: 'Úsek',
      }),
      width: 100,
    },
    {
      datakey: 'type.tbpp.name',
      name: intl.formatMessage({
        id: 'ESD__WORK_DONE_COLLECTION__TABLE__COLUMN__TBPP',
        defaultMessage: 'TBPP',
      }),
      width: 200,
      visible: showTBPP,
    },
  ];
}
