/**
 * Region person supplier assignment permissions
 */
export enum PermissionPersonAssignmentSupplier {
  /**
   * Prehľad členov dodávateľa
   */
  PERSON_ASSIGNMENT_SUPPLIER_LIST = 'PERSON_ASSIGNMENT_SUPPLIER_LIST',

  /**
   * Pridávanie členov dodávateľa
   */
  PERSON_ASSIGNMENT_SUPPLIER_CREATE = 'PERSON_ASSIGNMENT_SUPPLIER_CREATE',

  /**
   * Odstránenie členov dodávateľa
   */
  PERSON_ASSIGNMENT_SUPPLIER_DELETE = 'PERSON_ASSIGNMENT_SUPPLIER_DELETE',
}
