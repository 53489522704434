import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ApiFilterOperation, FormAutocomplete } from '@eas/common-web';
import {
  useAllPersons,
  usePersonAssignedList,
} from '@modules/person/person-api';
import { personLabelMapper } from '@utils/label-mapper';

export function PersonAssignDialog({
  supplierId,
  primaryJournalId,
  partialJournalId,
}: {
  supplierId: string | undefined;
  primaryJournalId: string | undefined;
  partialJournalId: string | undefined;
}) {
  // This hook returns all assigned people to a specific journal (primary or partial or entry).
  const { result } = usePersonAssignedList({
    skip: false,
    supplierId,
    primaryJournalId,
    partialJournalId,
  });

  const allPeopleInAssignmentListIds = (result?.items ?? []).map(
    (p) => p.person.id
  );

  const persons = useAllPersons({
    filters: [
      {
        operation: ApiFilterOperation.NOT,
        filters: [
          {
            field: 'id',
            operation: ApiFilterOperation.IN,
            values: allPeopleInAssignmentListIds,
          },
        ],
      },
    ],
  });

  return (
    <>
      <FormAutocomplete
        name="person"
        source={persons}
        labelMapper={personLabelMapper}
        label={
          <FormattedMessage
            id="ESD__PERSON__COLLECTION__FIELD_LABEL__PERSON"
            defaultMessage="Osoba"
          />
        }
        required
      />
    </>
  );
}
