import React, { ComponentPropsWithoutRef, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  CollectionField,
  Filter,
  ScrollableSource,
  UserContext,
} from '@eas/common-web';
import { Me, Timesheet } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';
import { TimesheetCreateDialog } from './dialogs/timesheet-create-dialog';
import { TimesheetUpdateDialog } from './dialogs/timesheet-update-dialog';
import { useColumns } from './timesheet-columns';
import {
  useCreateValidationSchema,
  useUpdateValidationSchema,
} from './timesheet-schemas';

interface TimesheetsFieldProps
  extends Partial<ComponentPropsWithoutRef<typeof CollectionField<Timesheet>>> {
  refFilters?: Filter[];
  currentJournalId: string;
  validityDate: string;
  source: ScrollableSource<Timesheet>;
}

export function TimesheetsField({
  currentJournalId,
  validityDate,
  source,
  ...overrides
}: TimesheetsFieldProps) {
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  // const filters = useFilterFields();

  return (
    <CollectionField
      title={
        <FormattedMessage
          id="ESD__FIELD_LABEL__TIMESHEETS"
          defaultMessage="Osoby"
        />
      }
      source={source}
      useColumns={useColumns}
      disabled={false}
      createAction={{
        hide: !hasPermission(
          Permission.JournalEntryTimesheet.JOURNAL_ENTRY_TIMESHEET_CREATE
        ),
        getUrl: () => EvidenceApiUrl.JOURNAL_ENTRY + '/timesheet/multiple',
        Dialog: () => TimesheetCreateDialog(currentJournalId),
        title: 'Vytvorenie',
        text: 'Vyplňte formulár',
        initValues: () => ({
          journalEntry: { id: currentJournalId },
          hours: 7.5,
          overtime: 0,
          date: validityDate,
        }),
        useSchema: useCreateValidationSchema,
        dialogWidth: 600,
        label: 'Pridať',
      }}
      readAction={{
        getUrl: (collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/timesheet/${collectionId}`,
      }}
      updateAction={{
        hide: !hasPermission(
          Permission.JournalEntryTimesheet.JOURNAL_ENTRY_TIMESHEET_UPDATE
        ),
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/timesheet/${collectionId}`,
        Dialog: TimesheetUpdateDialog,
        useSchema: useUpdateValidationSchema,
        dialogWidth: 600,
        title: 'Upravenie',
        text: 'Vyplňte formulár',
      }}
      deleteAction={{
        hide: !hasPermission(
          Permission.JournalEntryTimesheet.JOURNAL_ENTRY_TIMESHEET_DELETE
        ),
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/timesheet/${collectionId}`,
        label: 'Odobrať',
      }}
      assignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/timesheet/${collectionId}`,
      }}
      unassignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/timesheet/${collectionId}`,
      }}
      // filters={filters}
      {...overrides}
    />
  );
}
