import { useContext, useMemo } from 'react';
import { SupplierDictionariesEnum } from 'src/enums/supplier-dictionaries';
import { AbortableFetch, UserContext, abortableFetch } from '@eas/common-web';
import { EvidenceApiUrl, Permission } from '@enums';

type ImportInfo = Record<
  SupplierDictionariesEnum,
  {
    callApi: (id: string, formData: { file: File | null }) => AbortableFetch;
    showButton: boolean;
  }
>;

export function useSupplierDictionariesImport(sourceUrls: EvidenceApiUrl[]) {
  const { hasPermission } = useContext(UserContext);

  const importInfo = useMemo(() => {
    return sourceUrls.reduce((acc, sourceUrl) => {
      const callApi = (id: string, formData: { file: File | null }) => {
        if (!formData.file) throw new Error('Nebol vložený CSV súbor');

        const dto = new FormData();
        dto.append('file', formData.file);

        return abortableFetch(sourceUrl + '/import', {
          method: 'POST',
          body: dto,
        });
      };

      /**
       * Check permissions for given sources and translation suffix - necessary to add into lang/sk.json
       */
      switch (sourceUrl) {
        case EvidenceApiUrl.MECHANISMS:
          acc[SupplierDictionariesEnum.MECHANISM] = {
            callApi,
            showButton: hasPermission(Permission.Mechanism.MECHANISM_IMPORT),
          };
          break;
        case EvidenceApiUrl.MECHANISM_CATEGORY:
          acc[SupplierDictionariesEnum.MECHANISM_CATEGORY] = {
            callApi,
            showButton: hasPermission(
              Permission.MechanismCategory.MECHANISM_CATEGORY_IMPORT
            ),
          };
          break;
        case EvidenceApiUrl.ABSENCE:
          acc[SupplierDictionariesEnum.ABSENCE] = {
            callApi,
            showButton: hasPermission(Permission.Absence.ABSENCE_IMPORT),
          };
          break;
        case EvidenceApiUrl.WORK:
          acc[SupplierDictionariesEnum.WORK] = {
            callApi,
            showButton: hasPermission(Permission.Work.WORK_IMPORT),
          };
          break;
        case EvidenceApiUrl.WORK_CATEGORY:
          acc[SupplierDictionariesEnum.WORK_CATEGORY] = {
            callApi,
            showButton: hasPermission(
              Permission.WorkCategory.WORK_CATEGORY_IMPORT
            ),
          };
          break;
        case EvidenceApiUrl.TBPP:
          acc[SupplierDictionariesEnum.TBPP] = {
            callApi,
            showButton: hasPermission(Permission.TBPP.TBPP_IMPORT),
          };
          break;
        case EvidenceApiUrl.PROFESSION:
          acc[SupplierDictionariesEnum.PROFESSION] = {
            callApi,
            showButton: hasPermission(Permission.Profession.PROFESSION_IMPORT),
          };
          break;
      }

      return acc;
    }, {} as ImportInfo);
  }, [hasPermission, sourceUrls]);

  return { importInfo };
}
