import { useContext, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  DetailContext,
  DetailHandle,
  SnackbarContext,
  SnackbarVariant,
} from '@eas/common-web';
import { PartialJournal, PrimaryJournal } from '@models';
import { EvidenceApiUrl, JournalTypeEnum } from '@enums';
import {
  KEP_PARTIAL_JOURNAL_ID,
  KEP_PRIMARY_JOURNAL_ID,
} from './sign-partial-journal-kep-hook';

const finalizeKepSignature = (
  journalId: string,
  sessionId: string,
  signSuccess: boolean,
  isPrimaryJournal: boolean
) =>
  fetch(
    `${
      isPrimaryJournal
        ? EvidenceApiUrl.PRIMARY_JOURNAL
        : EvidenceApiUrl.PARTIAL_JOURNAL
    }/${journalId}/sign/kep/finalize`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify({
        sessionId,
        signSuccess,
        checkCertInfo: true,
      }),
    }
  );
// TODO: We should refactor the search params into separate hook
export const useFinalizeKepSignature = (type: JournalTypeEnum) => {
  const {
    source: { data },
    isExisting,
    refreshAll,
  } = useContext<DetailHandle<PrimaryJournal | PartialJournal>>(DetailContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const sessionId = searchParams.get('sessionId');
  const status = searchParams.get('status');
  const history = useHistory();

  const isPrimaryJournal = type === JournalTypeEnum.PRIMARY;

  useEffect(() => {
    const finishSignature = async (
      journalId: string,
      sessionId: string,
      signSuccess: boolean,
      isPrimaryJournal: boolean
    ) => {
      try {
        // If the signing at external service was successful, we send in the finishing api call in payload signSuccess: true (we get the status from service in the search params).
        // If not successful, we have to close the session by sending signSuccess: false.
        const res = await finalizeKepSignature(
          journalId,
          sessionId,
          signSuccess,
          isPrimaryJournal
        );

        if (res.ok) {
          signSuccess
            ? showSnackbar(
                'Dokument bol úspešne podpísaný.',
                SnackbarVariant.SUCCESS,
                false
              )
            : showSnackbar(
                'Dokument sa nepodarilo podpísať.',
                SnackbarVariant.WARNING,
                false
              );

          searchParams.delete('status');
          searchParams.delete('sessionId');
          history.replace({ search: searchParams.toString() });

          localStorage.removeItem(
            isPrimaryJournal ? KEP_PRIMARY_JOURNAL_ID : KEP_PARTIAL_JOURNAL_ID
          );

          signSuccess && refreshAll();
        } else {
          const err = res.json();
          throw err;
        }
      } catch (e) {
        searchParams.delete('status');
        searchParams.delete('sessionId');
        history.replace({ search: searchParams.toString() });

        localStorage.removeItem(
          isPrimaryJournal ? KEP_PRIMARY_JOURNAL_ID : KEP_PARTIAL_JOURNAL_ID
        );

        showSnackbar(
          'Pri dokončovaní podpisu sa niečo pokazilo.',
          SnackbarVariant.ERROR,
          false
        );
      }
    };

    if (data?.id && sessionId && status)
      finishSignature(
        data.id,
        sessionId,
        status === 'SignSuccess',
        isPrimaryJournal
      );
  }, [
    showSnackbar,
    isExisting,
    refreshAll,
    sessionId,
    status,
    history,
    searchParams,
    data?.id,
    isPrimaryJournal,
  ]);
};
