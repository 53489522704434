export enum ConstructionType {
  /**
   * Dlouhotrvající stavby
   */
  LONG_TERM = 'LONG_TERM',

  /**
   * Krátkotrvající stavby - Líniová stavby
   */
  SHORT_TERM = 'SHORT_TERM',
}
