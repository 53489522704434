import React, { useContext } from 'react';
import {
  DictionaryEvidence,
  Evidence,
  ScheduleContext,
  ScheduleJob,
  scheduleJobsFactory,
} from '@eas/common-web';
import { Permission } from '@enums';
import { useEvidencePermission } from '@utils/permission-hook';
import { JobsActionbar } from './jobs-actionbar';

export function ScheduleJobs() {
  const permissions = useEvidencePermission({
    options: {
      NEW: [],
      EDIT: [],
      REMOVE: [],
      REFRESH: [Permission.Default.SCHEDULE_JOBS_ALL],
      SHARE: [Permission.Default.SCHEDULE_JOBS_ALL],
      CHECK: [],
      SAVE: [],
      CLOSE: [],
    },
  });

  const validationSchema = scheduleJobsFactory.useValidationSchema();
  const { jobUrl: url } = useContext(ScheduleContext);

  const evidence = DictionaryEvidence.useDictionaryEvidence<ScheduleJob>({
    identifier: 'SCHEDULE_JOBS',
    apiProps: {
      url,
    },
    tableProps: {
      columns: scheduleJobsFactory.useColumns(),
      tableName: 'Naplánované časové úlohy',
      showReportButton: false,
    },
    detailProps: {
      toolbarProps: {
        title: 'Naplánovaná časová úloha',
        ActionBar: JobsActionbar,
        showBtn: permissions,
      },
      FieldsComponent: scheduleJobsFactory.Fields,
      validationSchema,
    },
  });

  return <Evidence {...evidence} />;
}
