import { useContext } from 'react';
import {
  DetailContext,
  DetailHandle,
  UserContext,
  abortableFetch,
} from '@eas/common-web';
import { PrimaryJournal } from '@models';
import { EvidenceApiUrl, Permission, PrimaryJournalState } from '@enums';
import { useUserAssignments } from '@utils/use-user-assignments';

export function useCancelPrimaryJournalDialog() {
  const {
    source: { data },
  } = useContext<DetailHandle<PrimaryJournal>>(DetailContext);

  const { hasPermission } = useContext(UserContext);
  const { isPrimaryJournalAssigned } = useUserAssignments();

  // If the user has PRIMARY_JOURNAL_CANCEL permission, then it cancels the journal directly without notifying the admin
  // To cancel with notification the user needs just PRIMARY_JOURNAL_UPDATE
  const isDirectCancel = hasPermission(
    Permission.PrimaryJournal.PRIMARY_JOURNAL_CANCEL
  );

  const callApi = (journalId: string, formValues: { reason: string }) => {
    return abortableFetch(
      `${EvidenceApiUrl.PRIMARY_JOURNAL}/${journalId}/cancel${
        isDirectCancel ? '' : '/notification'
      }`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        body: formValues?.reason,
      }
    );
  };

  const showButton =
    data?.state !== PrimaryJournalState.CANCELLED &&
    hasPermission(Permission.PrimaryJournal.PRIMARY_JOURNAL_UPDATE) &&
    isPrimaryJournalAssigned(data?.id);

  return { callApi, showButton, isDirectCancel };
}
