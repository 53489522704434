import React, { useEffect } from 'react';
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DetailContext,
  DetailHandle,
  DetailMode,
  EvidenceContext,
  SnackbarContext,
  SnackbarVariant,
  UserContext,
} from '@eas/common-web';
import { useValidationSchema as useWorkCategorySchema } from '@modules/dict__work-category/work-category-schema';
import { useValidationSchema as useWorkSchema } from '@modules/dict__work/work-schema';
import {
  SUPPLIER_WORK_CATEGORY_DICTIONARY,
  SUPPLIER_WORK_DICTIONARY,
} from '@modules/suppliers/supplier-hooked-sources';
import {
  SupplierDictionaryActionBar,
  SupplierDictionaryField,
} from '@composite/supplier-dictionary-field';
import { Me, Supplier, Work, WorkCategory } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';
import { WorkCreateDialog } from './dialogs/work-create-dialog';
import { useColumns } from './work-columns';
import { useFilterFields } from './work-filter-fields';

export function SupplierWorkFields() {
  const { user, hasPermission } = useContext<UserContext<Me>>(UserContext);
  const {
    source: { data },
    mode,
  } = useContext<DetailHandle<Supplier>>(DetailContext);

  const { hookedSources } = useContext(EvidenceContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const supplierId = data?.id || '';
  const workSource = hookedSources[SUPPLIER_WORK_DICTIONARY]?.source;
  const workCategoriesSource =
    hookedSources[SUPPLIER_WORK_CATEGORY_DICTIONARY]?.source;

  const disabled =
    mode !== DetailMode.VIEW || supplierId !== user?.supplier?.id;
  const workFilters = useFilterFields();

  const hasMissingPermissions = ![
    Permission.Work.WORK_LIST_SUPPLIER,
    Permission.WorkCategory.WORK_CATEGORY_LIST_SUPPLIER,
  ].every((perm) => hasPermission(perm));

  useEffect(() => {
    if (hasMissingPermissions) {
      showSnackbar(
        <FormattedMessage
          id="ESD__SUPPLIER__DETAIL__ABSENCE_UNAVAILABLE__SNACKBAR"
          defaultMessage="Chýbajúce oprávnenia - Pre zobrazenie hodnôt dodávateľských číselníkov práce nemáte dostatočné oprávnenia"
        />,
        SnackbarVariant.WARNING,
        true
      );
    }
  }, [hasMissingPermissions, showSnackbar]);

  return hasMissingPermissions ? (
    <></>
  ) : (
    <>
      {data && (
        <SupplierDictionaryActionBar
          importSourceUrls={[EvidenceApiUrl.WORK]}
          takeoverSourceUrls={[EvidenceApiUrl.WORK]}
        />
      )}
      <SupplierDictionaryField<WorkCategory>
        supplierId={supplierId}
        title={
          <FormattedMessage
            id="ESD__SUPPLIER__DETAIL__FIELD_LABEL__WORK_CATEGORY__TITLE"
            defaultMessage="Dodávateľský číselník - Typ vedenia"
          />
        }
        sourceUrl={EvidenceApiUrl.WORK_CATEGORY}
        source={workCategoriesSource}
        disabled={disabled}
        useSchema={useWorkCategorySchema}
      />
      <SupplierDictionaryField<Work>
        supplierId={supplierId}
        title={
          <FormattedMessage
            id="ESD__SUPPLIER__DETAIL__FIELD_LABEL__WORK__TITLE"
            defaultMessage="Dodávateľský číselník - Typ vykonanej práce"
          />
        }
        sourceUrl={EvidenceApiUrl.WORK}
        source={workSource}
        disabled={disabled}
        createDialog={WorkCreateDialog}
        useColumns={useColumns}
        filters={workFilters}
        useSchema={useWorkSchema}
      />
    </>
  );
}
