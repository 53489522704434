import React from 'react';
import { useIntl } from 'react-intl';
import {
  AuthoredEvidence,
  DomainObject,
  TableCells,
  TableColumn,
  TableFieldCells,
  TableFieldColumn,
  TableFilterCells,
} from '@eas/common-web';
import {
  usePartialJournalStates,
  usePartialJournalTypes,
} from '@modules/partial-journals/partial-journal-api';
import { usePersons } from '@modules/person/person-api';
import { useTenants } from '@modules/tenant/tenant-api';
import { Redirect } from '@components/redirect/redirect';
import { PrimaryJournal } from '@models';
import { EvidenceBrowserUrl } from '@enums';
import { personLabelMapper } from '@utils/label-mapper';
import {
  useConstructionTypes,
  usePrimaryJournalState,
} from './primary-journal-api';

export function useColumns(): TableColumn<PrimaryJournal>[] {
  const intl = useIntl();
  const { columnCreated, columnCreatedBy, columnUpdated, columnUpdatedBy } =
    AuthoredEvidence.useAuthoredColumns<PrimaryJournal>();

  return [
    {
      datakey: 'tenant',
      filterkey: 'tenant.id',
      sortkey: 'tenant.name',
      name: intl.formatMessage({
        id: 'ESD__PRIMARY_JOURNAL__COLUMN__TENANT',
        defaultMessage: 'Vlastník',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.dictionaryColumnMapper,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory(useTenants),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'construction.name',
      name: intl.formatMessage({
        id: 'ESD_PRIMARY_JOURNAL__COLUMN__CONSTRUCTION_NAME',
        defaultMessage: 'Názov stavby',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'construction.siteId',
      name: intl.formatMessage({
        id: 'ESD_PRIMARY_JOURNAL__COLUMN__CONSTRUCTION_SITE_ID',
        defaultMessage: 'Číslo stavby',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'construction.type',
      filterkey: 'construction.type.id',
      sortkey: 'construction.type.name',
      name: intl.formatMessage({
        id: 'ESD_PRIMARY_JOURNAL__COLUMN__CONSTRUCTION_TYPE',
        defaultMessage: 'Typ stavby',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useConstructionTypes),
      FilterComponent:
        TableFilterCells.useFilterSelectCellFactory(useConstructionTypes),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'team',
      name: intl.formatMessage({
        id: 'ESD_PRIMARY_JOURNAL__COLUMN__TEAM',
        defaultMessage: 'Tím zhotoviteľa',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'state',
      filterkey: 'state.id',
      sortkey: 'state.name',
      name: intl.formatMessage({
        id: 'ESD__PRIMARY_JOURNAL__COLUMN__STATE',
        defaultMessage: 'Stav hlavného denníka',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        usePrimaryJournalState
      ),
      valueMapper: TableCells.useSelectCellFactory(usePrimaryJournalState),
      sortable: true,
      filterable: true,
    },
    {
      datakey: columnCreated.datakey,
      name: columnCreated.name,
      width: 120,
      CellComponent: TableCells.DateCell,
      sortable: true,
      filterable: true,
    },
    columnCreatedBy,
    columnUpdated,
    columnUpdatedBy,
  ];
}

export function usePartialJournalTableColumns<
  T extends DomainObject
>(): TableFieldColumn<T>[] {
  const intl = useIntl();

  return [
    {
      datakey: 'id',
      name: 'Odkaz',
      width: 80,
      CellComponent: (props) => (
        <Redirect
          url={EvidenceBrowserUrl.PARTIAL_JOURNAL}
          id={props.rowValue!.id}
        />
      ),
    },
    {
      datakey: 'name',
      name: intl.formatMessage({
        id: 'ESD__PRIMARY_JOURNAL__PARTIAL_TABLE_FIELD_COLUMN__NAME',
        defaultMessage: 'Názov stavby',
      }),
      width: 150,
    },
    {
      datakey: 'leader',
      name: intl.formatMessage({
        id: 'ESD__PRIMARY_JOURNAL__PARTIAL_TABLE_FIELD_COLUMN__LEADER',
        defaultMessage: 'Stavbyvedúci dodávateľa',
      }),
      CellComponent: TableFieldCells.useAutocompleteCellFactory({
        dataHook: usePersons,
        labelMapper: personLabelMapper,
        multiple: false,
      }),
      width: 150,
    },
    {
      datakey: 'type',
      sortkey: 'type.name',
      name: intl.formatMessage({
        id: 'ESD__PRIMARY_JOURNAL__PARTIAL_TABLE_FIELD_COLUMN__TYPE',
        defaultMessage: 'Typ dielčieho denníku',
      }),
      width: 150,
      /*  CellComponent: TableFieldCells.useAutocompleteCellFactory({
        dataHook: usePartialJournalTypes,
        multiple: false,
      }), */
      CellComponent: TableFieldCells.useSelectCellFactory(
        usePartialJournalTypes
      ),
    },
    {
      datakey: 'state',
      sortkey: 'state.name',
      name: intl.formatMessage({
        id: 'ESD__PRIMARY_JOURNAL__PARTIAL_TABLE_FIELD_COLUMN__STATE',
        defaultMessage: 'Stav dielčieho denníku',
      }),
      width: 200,
      CellComponent: TableFieldCells.useSelectCellFactory(
        usePartialJournalStates
      ),
    },
  ];
}
