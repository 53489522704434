import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Tabs, TabsConfig, UserContext } from '@eas/common-web';
import { useFinalizeKepSignature } from '@components/kep-signature/kep-signature-api';
import { Me } from '@models';
import { JournalTypeEnum, Permission } from '@enums';
import { OverviewFields } from './fields/overview';
import { PersonAssignmentFields } from './fields/person-assignment';
import { PartialJournalSignaturesFields } from './fields/signatures-fields';

export function Fields() {
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  // Hook to finish KEP signature in app, after signing at the external signing service.
  useFinalizeKepSignature(JournalTypeEnum.PARTIAL);

  const config: TabsConfig = [
    {
      label: (
        <FormattedMessage
          id="ESD__PARTIAL_JOURNAL__TAB_TITLE__OVERVIEW"
          defaultMessage="Základné údaje"
        />
      ),
      key: 'OVERVIEW',
      validationKeys: [],
      content: <OverviewFields />,
    },
    ...(hasPermission(
      Permission.PersonAssignmentPartialJournal
        .PERSON_ASSIGNMENT_PARTIAL_JOURNAL_LIST
    )
      ? [
          {
            label: (
              <FormattedMessage
                id="ESD__PARTIAL_JOURNAL__TAB_TITLE__PERSON_ASSIGNMENT"
                defaultMessage="Členovia"
              />
            ),
            key: 'PARTIAL_JOURNAL_PERSON_ASSIGNMENT',
            validationKeys: [],
            content: <PersonAssignmentFields />,
          },
        ]
      : []),
    {
      label: (
        <FormattedMessage
          id="ESD__PARTIAL_JOURNAL__TAB_TITLE__SIGNATURES"
          defaultMessage="Podpisy"
        />
      ),
      key: 'PARTIAL_JOURNAL_SIGNATURES',
      validationKeys: [],
      content: <PartialJournalSignaturesFields />,
    },
  ];
  return <Tabs config={config} />;
}
