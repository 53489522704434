import React, { ComponentPropsWithoutRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { CollectionField, Filter, ScrollableSource } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';
import { useColumns } from './journal-entry-columns';
import { useValidationSchema } from './journal-entry-schema';
import { EntryCreateDialog } from './dialogs/journal-entry-create-dialog';
import { JournalEntry, PrimaryJournal } from '@models';

interface JournalEntryFieldProps
  extends Partial<
    ComponentPropsWithoutRef<typeof CollectionField<JournalEntry>>
  > {
  refFilters?: Filter[];
  currentRef: Pick<PrimaryJournal, 'id' | 'journalType'>;
  source: ScrollableSource<JournalEntry>;
}

export function JournalEntryField({
  currentRef,
  source,
  ...overrides
}: JournalEntryFieldProps) {
  //const filters = useFilterFields();

  return (
    <CollectionField
      title={
        <FormattedMessage
          id="ESD__FIELD_LABEL__ENTRY"
          defaultMessage="Denné záznamy"
        />
      }
      // defaultExpanded={false}
      source={source}
      useColumns={useColumns}
      disabled={false}
      createAction={{
        getUrl: () => EvidenceApiUrl.JOURNAL_ENTRY,
        Dialog: EntryCreateDialog,
        initValues: () => ({
          journal: currentRef,
          validityDate: new Date().toISOString(),
        }),
        useSchema: useValidationSchema,
        dialogWidth: 600,
      }}
      readAction={{
        getUrl: (collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/${collectionId}`,
      }}
      updateAction={{
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/${collectionId}`,
        Dialog: EntryCreateDialog,
        useSchema: useValidationSchema,
        dialogWidth: 600,
        hide: true,
      }}
      deleteAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/${collectionId}`,
      }}
      assignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/${collectionId}`,
      }}
      unassignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/${collectionId}`,
      }}
      //  filters={filters}
      {...overrides}
    />
  );
}
