import SignaturesTableField from '@composite/signatures-field/signatures-table-field';
import {
  DetailContext,
  DetailHandle,
  SnackbarContext,
  SnackbarVariant,
} from '@eas/common-web';
import { PrimaryJournal } from '@models';
import { useUserAssignments } from '@utils/use-user-assignments';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

export function PrimaryJournalSignaturesFields() {
  const {
    source: { data },
  } = useContext<DetailHandle<PrimaryJournal>>(DetailContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const { isPrimaryJournalAssigned } = useUserAssignments();

  const hasMissingPermissions = !isPrimaryJournalAssigned(data?.id);

  useEffect(() => {
    if (hasMissingPermissions) {
      showSnackbar(
        <FormattedMessage
          id="ESD__PRIMARY_JOURNAL__DETAIL__SIGNATURES_UNAVAILABLE__SNACKBAR"
          defaultMessage="Chýbajúce oprávnenia - Nie ste priamym členom aktuálne otvoreného hlavného denníka preto nemáte oprávnenie k zobrazovaniu jeho podpisov"
        />,
        SnackbarVariant.WARNING,
        true
      );
    }
  }, [hasMissingPermissions, showSnackbar]);

  return !hasMissingPermissions ? <SignaturesTableField /> : <></>;
}
