import { isEmpty } from 'lodash';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ApiFilterOperation,
  FormAutocomplete,
  FormContext,
  FormDecimalField2,
  FormLocalDateField,
  FormPanel,
  FormTextField,
} from '@eas/common-web';
import { useAbsences } from '@modules/dict__absence/absence-api';
import { useProfessions } from '@modules/dict__profession/profession-api';
import { usePersons } from '@modules/person/person-api';
import { useWorkGroups } from '@modules/work-group/work-group-api';
import { personLabelMapper } from '@utils/label-mapper';
import { useTimesheetList } from '../timesheet-api';

interface WorkGroup {
  id: string;
  members: { id: string[]; flat: string[] };
}

export function TimesheetCreateDialog(journalEntryId: string) {
  const { setFieldValue } = useContext(FormContext);

  const { result } = useTimesheetList({ skip: false, journalEntryId });

  const allPeopleInTimesheetIds = (result?.items ?? []).map((r) => r.person.id);

  // filter is added, so we have in autocomplete only people which are not yet assigned in timesheet
  const persons = usePersons({
    filters: [
      {
        operation: ApiFilterOperation.NOT,
        filters: [
          {
            field: 'id',
            operation: ApiFilterOperation.IN,
            values: allPeopleInTimesheetIds,
          },
        ],
      },
    ],
  });
  const professions = useProfessions();
  const absences = useAbsences();
  const workGroups = useWorkGroups();

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__MECHANISMS__COLLECTION__PANEL_LABEL__BASIC"
            defaultMessage="Základné"
          />
        }
      >
        <FormAutocomplete
          source={workGroups}
          name="workGroup"
          label={
            <FormattedMessage
              id="ESD__WORK_GROUP__DETAIL__FIELD_LABEL__WORK_GROUP"
              defaultMessage="Pracovná skupina"
            />
          }
          notifyChange={(value) => {
            const workGroup = value as WorkGroup | null;

            if (workGroup === null) {
              setFieldValue('people', null);
              return;
            }

            const workGroupPeople = workGroup?.members?.id ?? [];
            const notAssignedPeople = workGroupPeople
              .map((personId, i) => ({
                id: personId,
                name: workGroup.members.flat[i],
              }))
              .filter((o) => !allPeopleInTimesheetIds.includes(o.id));

            if (!isEmpty(notAssignedPeople))
              setFieldValue('people', notAssignedPeople);
          }}
        />
        <FormAutocomplete
          source={persons}
          label={
            <FormattedMessage
              id="ESD__TIMESHEET__COLLECTION__FIELD_LABEL__PERSON"
              defaultMessage="Osoby"
            />
          }
          name="people"
          required
          labelMapper={personLabelMapper}
          multiple
          notifyChange={(people) => {
            if (isEmpty(people)) setFieldValue('people', null);
          }}
        />
        <FormAutocomplete
          source={professions}
          name="profession"
          label={
            <FormattedMessage
              id="ESD__TIMESHEET__COLLECTION__FIELD_LABEL__WORK_UNITS"
              defaultMessage="Pracovná pozícia"
            />
          }
          helpLabel="Nepovinný údaj. Pokiaľ pole zostane prázdne, bude pre danú osobu použitá pracovná pozícia, ktorú má daná osoba priradenú v agende 'Osoby'"
        />

        <FormLocalDateField
          name="date"
          label={
            <FormattedMessage
              id="ESD__TIMESHEET__COLLECTION__FIELD_LABEL__DATE"
              defaultMessage="Dátum"
            />
          }
          disabled
        />
        <FormDecimalField2
          name="hours"
          label={
            <FormattedMessage
              id="ESD__TIMESHEET__COLLECTION__FIELD_LABEL__HOURS"
              defaultMessage="Odpracované hodiny"
            />
          }
          required
          decimalDigits={1}
          fixedDecimalDigits={false}
          maxValue={24}
        />
        <FormDecimalField2
          name="overtime"
          label={
            <FormattedMessage
              id="ESD__TIMESHEET__COLLECTION__FIELD_LABEL__OVERTIME"
              defaultMessage="Nadčas"
            />
          }
          required
          decimalDigits={1}
          fixedDecimalDigits={false}
          maxValue={24}
        />
      </FormPanel>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__MECHANISMS__COLLECTION__PANEL_LABEL__ABSENCE"
            defaultMessage="Neprítomnosť"
          />
        }
      >
        <FormAutocomplete
          source={absences}
          label={
            <FormattedMessage
              id="ESD__TIMESHEET__COLLECTION__FIELD_LABEL__ABSENCE"
              defaultMessage="Neprítomnosť"
            />
          }
          name="absence.type"
          notifyChange={(val) => {
            if (!val) setFieldValue('absence', null);
          }}
        />
        <FormDecimalField2
          name="absence.hours"
          label={
            <FormattedMessage
              id="ESD__TIMESHEET__COLLECTION__FIELD_LABEL__ABSENCE_HOURS"
              defaultMessage="Neprítomnosť - hodiny"
            />
          }
          decimalDigits={1}
          fixedDecimalDigits={false}
          maxValue={24}
        />
        <FormTextField
          name="absence.comment"
          label={
            <FormattedMessage
              id="ESD__TIMESHEET__COLLECTION__FIELD_LABEL__ABSENCE_COMMENT"
              defaultMessage="Komentár"
            />
          }
        />
      </FormPanel>
    </>
  );
}
