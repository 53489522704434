import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import { DetailActionButton } from '@eas/common-web';
import { TertiaryDetailActionbarButton } from '@components/button/tertiary-detail-actionbar-button';
import { FormImportField } from '@components/form-import-field/form-import-field';
import { EvidenceApiUrl } from '@enums';
import { useSupplierDictionariesImport } from '../dialog-actions/supplier-dictionary-import-hook';

interface SupplierDictionaryImportActionButtonsProps {
  importSourceUrls: EvidenceApiUrl[];
  disableEdit: boolean;
}

function useValidationSchema() {
  return Yup.object<{ file: File }>().shape({
    file: Yup.mixed().nullable().required(),
  });
}

export function SupplierDictionaryImportActionButtons({
  importSourceUrls,
  disableEdit,
}: SupplierDictionaryImportActionButtonsProps) {
  const { importInfo } = useSupplierDictionariesImport(importSourceUrls);

  const validationSchema = useValidationSchema();

  return (
    <>
      {Object.entries(importInfo).map(([key, { callApi, showButton }]) =>
        showButton ? (
          <DetailActionButton
            key={`${key}_IMPORT`}
            promptKey={`${key}_IMPORT`}
            buttonLabel={
              <FormattedMessage
                id={`ESD__SUPPLIER__DETAIL__DICTIONARY_TAB__IMPORT__BUTTON_LABEL__${key}`}
                defaultMessage="Import"
              />
            }
            dialogTitle={
              <FormattedMessage
                id={`ESD__SUPPLIER__DETAIL__DICTIONARY_TAB__IMPORT__DIALOG_TITLE__${key}`}
                defaultMessage="Import súboru CSV"
              />
            }
            dialogText={
              <FormattedMessage
                id={`ESD__SUPPLIER__DETAIL__DICTIONARY_TAB__IMPORT__DIALOG_TEXT__${key}`}
                defaultMessage={`Pre import dát do číselníka, vložte súbor.`}
              />
            }
            apiCall={callApi}
            ButtonComponent={TertiaryDetailActionbarButton}
            buttonDisabled={disableEdit}
            FormFields={() => (
              <>
                <FormImportField
                  name="file"
                  label={
                    <FormattedMessage
                      id="ESD____TABLE_TOOLBAR_DIALOG__FIELD_LABEL__FILE"
                      defaultMessage="Súbor CSV"
                    />
                  }
                  acceptedFormats={['.csv']}
                  required
                />
              </>
            )}
            formValidationSchema={validationSchema}
          />
        ) : (
          <></>
        )
      )}
    </>
  );
}
