import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Tabs, TabsConfig, UserContext } from '@eas/common-web';
import { Me } from '@models';
import { Permission } from '@enums';
import { JournalEntryAttachmentsFields } from './fields/attachments-fields';
import { JournalEntryBozpFields } from './fields/bozp-fields';
import { JournalEntryOverviewFields } from './fields/overview-fields';
import { JournalEntrySignaturesFields } from './fields/signatures-fields';

export function Fields() {
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  const config: TabsConfig = [
    {
      label: (
        <FormattedMessage
          id="ESD__JOURNAL_ENTRY__TAB_TITLE__OVERVIEW"
          defaultMessage="Základné údaje"
        />
      ),
      key: 'ENTRY_OVERVIEW',
      validationKeys: [],
      content: <JournalEntryOverviewFields />,
    },
    {
      label: (
        <FormattedMessage
          id="ESD__JOURNAL_ENTRY__TAB_TITLE__PERSON_ATTACHMENTS"
          defaultMessage="Prílohy"
        />
      ),
      key: 'JOURNAL_ENTRY_ATTACHMENTS',
      validationKeys: [],
      content: hasPermission(
        Permission.JournalEntryAttachment.JOURNAL_ENTRY_ATTACHMENT_LIST
      ) ? (
        <JournalEntryAttachmentsFields />
      ) : (
        <></>
      ),
    },
    {
      label: (
        <FormattedMessage
          id="ESD__JOURNAL_ENTRY__TAB_TITLE__BOZP"
          defaultMessage="BOZP"
        />
      ),
      key: 'JOURNAL_ENTRY_BOZP',
      validationKeys: [],
      content: <JournalEntryBozpFields />,
    },
    {
      label: (
        <FormattedMessage
          id="ESD__JOURNAL_ENTRY__TAB_TITLE__SIGNATURES"
          defaultMessage="Podpisy"
        />
      ),
      key: 'JOURNAL_ENTRY_SIGNATURES',
      validationKeys: [],
      content: <JournalEntrySignaturesFields />,
    },
  ];
  return <Tabs config={config} />;
}
