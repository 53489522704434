import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DetailContext,
  DetailHandle,
  DetailMode,
  EvidenceContext,
  SnackbarContext,
  SnackbarVariant,
  UserContext,
} from '@eas/common-web';
import {
  SupplierDictionaryActionBar,
  SupplierDictionaryField,
} from '@composite/supplier-dictionary-field';
import { Me, Mechanism, MechanismCategory, Supplier } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';
import { useValidationSchema as mechanismCategorySchema } from '../../../dict__mechanism-category/mechanism-category-schema';
import { useValidationSchema as mechanismSchema } from '../../../dict__mechanisms/mechanism-schema';
import {
  SUPPLIER_MECHANISM_CATEGORY_DICTIONARY,
  SUPPLIER_MECHANISM_DICTIONARY,
} from '../../supplier-hooked-sources';
import { MechanismCreateDialog } from './dialogs/mechanism-create-dialog';
import { useColumns } from './mechanisms-columns';
import { useFilterFields } from './mechanisms-filter-fields';

export function SupplierMechanismsFields() {
  const { user, hasPermission } = useContext<UserContext<Me>>(UserContext);
  const {
    source: { data },
    mode,
  } = useContext<DetailHandle<Supplier>>(DetailContext);

  const { hookedSources } = useContext(EvidenceContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const supplierId = data?.id || '';
  const mechanismsSource = hookedSources[SUPPLIER_MECHANISM_DICTIONARY]?.source;
  const mechanismCategoriesSource =
    hookedSources[SUPPLIER_MECHANISM_CATEGORY_DICTIONARY]?.source;

  const mechanismFilters = useFilterFields();
  const disabled =
    mode !== DetailMode.VIEW || supplierId !== user?.supplier?.id;

  const hasMissingPermissions = ![
    Permission.Mechanism.MECHANISM_LIST_SUPPLIER,
    Permission.MechanismCategory.MECHANISM_CATEGORY_LIST_SUPPLIER,
  ].every((perm) => hasPermission(perm));

  useEffect(() => {
    if (hasMissingPermissions) {
      showSnackbar(
        <FormattedMessage
          id="ESD__SUPPLIER__DETAIL__ABSENCE_UNAVAILABLE__SNACKBAR"
          defaultMessage="Chýbajúce oprávnenia - Pre zobrazenie hodnôt dodávateľských číselníkov mechanizmov nemáte dostatočné oprávnenia"
        />,
        SnackbarVariant.WARNING,
        true
      );
    }
  }, [hasMissingPermissions, showSnackbar]);

  return hasMissingPermissions ? (
    <></>
  ) : (
    <>
      {data && (
        <SupplierDictionaryActionBar
          importSourceUrls={[EvidenceApiUrl.MECHANISMS]}
          takeoverSourceUrls={[EvidenceApiUrl.MECHANISM_CATEGORY]}
        />
      )}
      <SupplierDictionaryField<MechanismCategory>
        supplierId={supplierId}
        title={
          <FormattedMessage
            id="ESD__SUPPLIER__DETAIL__FIELD_LABEL__MECHANISM_CATEGORY__TITLE"
            defaultMessage="Dodávateľský číselník - Kategória mechanizmu"
          />
        }
        sourceUrl={EvidenceApiUrl.MECHANISM_CATEGORY}
        source={mechanismCategoriesSource}
        useSchema={mechanismCategorySchema}
        disabled={disabled}
      />
      <SupplierDictionaryField<Mechanism>
        supplierId={supplierId}
        title={
          <FormattedMessage
            id="ESD__SUPPLIER__DETAIL__FIELD_LABEL__MECHANISMS__TITLE"
            defaultMessage="Dodávateľský číselník - Mechanizmy"
          />
        }
        sourceUrl={EvidenceApiUrl.MECHANISMS}
        source={mechanismsSource}
        disabled={disabled}
        createDialog={MechanismCreateDialog}
        useColumns={useColumns}
        filters={mechanismFilters}
        useSchema={mechanismSchema}
      />
    </>
  );
}
