import { JournalEntry, MechanismCategory } from '@models';
import * as Yup from 'yup';

export function useValidationSchema() {
  return Yup.object<JournalEntry>().shape({
    hours: Yup.number().required(),
    distance: Yup.number().required(),
    type: Yup.mixed().required(),
    // for UX purposes, BE category is optional
    category: Yup.mixed<MechanismCategory>().when('type', {
      is: (type) => !type,
      then: Yup.mixed().nullable().required(),
    }),
  });
}
