import { useIntl } from 'react-intl';
import {
  ApiFilterOperation,
  DictionaryEvidence,
  TableCells,
  TableFilterCells,
} from '@eas/common-web';
import { useTenants } from '@modules/tenant/tenant-api';
import { TBPP } from '@models';

export const useColumns = () => {
  const intl = useIntl();

  const { columnCreated, columnCreatedBy, columnActive, columnName } =
    DictionaryEvidence.useDictionaryColumns<TBPP>();

  return [
    {
      datakey: 'tenant',
      sortkey: 'tenant.name',
      filterkey: 'tenant.id',
      name: intl.formatMessage({
        id: 'ESD__MECHANISM__TABLE__COLUMN__TENANT',
        defaultMessage: 'Vlastník',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.dictionaryColumnMapper,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory(useTenants),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      sortable: true,
    },
    columnName,
    {
      datakey: 'code',
      name: intl.formatMessage({
        id: 'ESD__TBPP__TABLE__COLUMN__CODE',
        defaultMessage: 'Číslo TBPP',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      filterable: true,
      sortable: true,
    },
    {
      datakey: 'externalId',
      name: intl.formatMessage({
        id: 'ESD__TBPP__TABLE__COLUMN__EXTERNAL_ID',
        defaultMessage: 'Externý identifikátor',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      filterable: true,
      sortable: true,
    },
    columnActive,
    columnCreated,
    columnCreatedBy,
  ];
};
