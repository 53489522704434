import { PersonField } from '@composite/person-collection/person-field';
import {
  DetailContext,
  DetailHandle,
  DetailMode,
  EvidenceContext,
  SnackbarContext,
  SnackbarVariant,
} from '@eas/common-web';
import { AssignmentType } from '@enums';
import { PrimaryJournal } from '@models';
import { useUserAssignments } from '@utils/use-user-assignments';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { PERSON_ASSSIGNMENT } from '../primary-journal-hooked-sources';

export function PersonAssignmentFields() {
  const {
    source: { data },
    mode,
    isLocked,
  } = useContext<DetailHandle<PrimaryJournal>>(DetailContext);
  const { hookedSources } = useContext(EvidenceContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const { isPrimaryJournalAssigned } = useUserAssignments();

  const personAssignment = hookedSources[PERSON_ASSSIGNMENT]?.source;

  const hasMissingPermissions = !isPrimaryJournalAssigned(data?.id);

  useEffect(() => {
    if (hasMissingPermissions) {
      showSnackbar(
        <FormattedMessage
          id="ESD__PRIMARY_JOURNAL__DETAIL__ASSIGNMENTS_UNAVAILABLE__SNACKBAR"
          defaultMessage="Chýbajúce oprávnenia - Nie ste priamym členom aktuálne otvoreného hlavného denníka preto nemáte oprávnenie k zobrazovaniu jeho členov"
        />,
        SnackbarVariant.WARNING,
        true
      );
    }
  }, [hasMissingPermissions, showSnackbar]);

  return !hasMissingPermissions ? (
    <>
      {data?.id && (
        <PersonField
          source={personAssignment}
          disabled={mode !== DetailMode.VIEW || isLocked}
          initialValues={{
            type: AssignmentType.PRIMARY_JOURNAL,
            journal: { id: data?.id },
          }}
        />
      )}
    </>
  ) : (
    <></>
  );
}
