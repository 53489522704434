import { formatDistanceToNow, parseISO } from 'date-fns';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import Card from '@material-ui/core/Card/Card';
import CardContent from '@material-ui/core/CardContent/CardContent';
import CardHeader from '@material-ui/core/CardHeader/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import { ActionButton, LocaleContext } from '@eas/common-web';
import { ApplicationNotification } from '@models';
import { NotificationEventType } from '@enums';
import { cancelPartialJournal } from './actions/cancel-partial-journal';
import { cancelPrimaryJournal } from './actions/cancel-primary-journal';
import { deleteNotificationApiCall } from './actions/delete-hook';
import { useRead } from './actions/read-hook';
import { useUnread } from './actions/unread-hook';

export const useStyles = makeStyles(() => ({
  notification: {
    cursor: 'default',
    width: '100%',
    height: '100%',
    whiteSpace: 'normal',
    boxShadow: '0 0 0',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  notificationContent: {
    whiteSpace: 'pre-wrap',
  },
  notificationSubheader: {
    fontSize: 11,
    fontWeight: 500,
  },
  notificationTitle: {
    fontSize: 14,
    fontWeight: 400,
  },
  notificationActionButton: {
    alignSelf: 'auto',
    marginRight: 0,
  },
}));

interface NotificationProps {
  item: ApplicationNotification;
  refreshNotifications: () => void;
}

const parseContent = (itemWithId: string | undefined) => {
  const parsed = itemWithId?.split('id:');

  const content = parsed?.[0];
  const journalId = parsed?.[1];

  return { content, journalId };
};

export function Notification({
  item,
  refreshNotifications,
}: NotificationProps) {
  const classes = useStyles();
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { handleRead } = useRead({ refresh: refreshNotifications });
  const { handleUnread } = useUnread({ refresh: refreshNotifications });

  const date = formatDistanceToNow(parseISO(item.created!), {
    locale: locale.dateFnsLocale,
    addSuffix: true,
  });

  const { content, journalId } = parseContent(item.content);

  return (
    <Card className={classes.notification}>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!item?.read && (
          <MenuItem onClick={() => handleRead(item.id)}>
            Označit jako přečtené
          </MenuItem>
        )}
        {item?.read && (
          <MenuItem onClick={() => handleUnread(item.id)}>
            Označit jako nepřečtené
          </MenuItem>
        )}
        <ActionButton
          promptKey="DELETE_NOTIFICATION"
          apiCall={() => deleteNotificationApiCall(item.id!)}
          ButtonComponent={(props) => (
            <MenuItem {...props}>{props.label}</MenuItem>
          )}
          buttonLabel={intl.formatMessage({
            id: 'ESD__NOTIFICATION__ACTIONMENU__DELETE',
            defaultMessage: 'Odstrániť notifikáciu',
          })}
          dialogText={intl.formatMessage({
            id: 'ESD__NOTIFICATION__ACTIONMENU__DELETE__TEXT',
            defaultMessage: 'Skutočne chcete odstrániť notifikáciu?',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ESD__NOTIFICATION__ACTIONMENU__DELETE__TITLE',
            defaultMessage: 'Upozornenie',
          })}
          onSuccess={async () => refreshNotifications()}
        />
        {(item.event ===
          NotificationEventType.PRIMARY_JOURNAL_CANCELLATION_REQUESTED ||
          item.event ===
            NotificationEventType.PARTIAL_JOURNAL_CANCELLATION_REQUESTED) &&
          !item.read && (
            <ActionButton
              promptKey="CANCEL_PRIMARY_CONFIRMATION"
              apiCall={() =>
                item.event ===
                NotificationEventType.PRIMARY_JOURNAL_CANCELLATION_REQUESTED
                  ? cancelPrimaryJournal(journalId!)
                  : cancelPartialJournal(journalId!)
              }
              ButtonComponent={(props) => (
                <MenuItem {...props}>{props.label}</MenuItem>
              )}
              buttonLabel={intl.formatMessage({
                id: 'ESD__NOTIFICATION__ACTIONMENU__CONFIRM_CANCEL',
                defaultMessage: 'Potvrdiť zrušenie denníku',
              })}
              dialogText={intl.formatMessage({
                id: 'ESD__NOTIFICATION__ACTIONMENU__CONFIRM_CANCEL__TEXT',
                defaultMessage: 'Skutočne chcete potvrdiť zrušenie denníku?',
              })}
              dialogTitle={intl.formatMessage({
                id: 'ESD__NOTIFICATION__ACTIONMENU__CONFIRM_CANCEL__TITLE',
                defaultMessage: 'Upozornenie',
              })}
              onSuccess={async () => {
                handleRead(item.id!);
              }}
            />
          )}
        {/*   <ActionButton
          promptKey="UNSUBSCRIBE_NOTIFICATION"
          apiCall={() => unsubscribeNotificationApiCall(item.event!)}
          ButtonComponent={(props) => (
            <MenuItem {...props}>{props.label}</MenuItem>
          )}
          buttonLabel={intl.formatMessage({
            id: 'ESD__NOTIFICATION__ACTIONMENU__UNSUBSCRIBE',
            defaultMessage: 'Nechci dostávat podobné notifikace',
          })}
          dialogText={intl.formatMessage({
            id: 'ESD__NOTIFICATION__ACTIONMENU__UNSUBSCRIBE__TEXT',
            defaultMessage:
              'Skutečně nechcete být upozorňováni o podobných událostech?',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ESD__NOTIFICATION__ACTIONMENU__UNSUBSCRIBE__TITLE',
            defaultMessage: 'Varování',
          })}
          onSuccess={async () => refreshNotifications()}
        /> */}
      </Menu>
      <CardHeader
        avatar={!item.read ? <NewReleasesIcon color="secondary" /> : null}
        action={
          <IconButton size="small" onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
        }
        title={
          <div
            className={classes.notificationTitle}
            dangerouslySetInnerHTML={{ __html: item.subject! }}
          />
        }
        subheader={date}
        classes={{
          action: classes.notificationActionButton,
          subheader: classes.notificationSubheader,
        }}
      />
      <CardContent>
        <Typography
          variant="body1"
          color="textSecondary"
          component="div"
          className={classes.notificationContent}
        >
          <div dangerouslySetInnerHTML={{ __html: content! }} />
        </Typography>
      </CardContent>
    </Card>
  );
}
