import React, { useContext } from 'react';
import { DetailContext, DetailHandle, UserContext } from '@eas/common-web';
import { Me, Supplier } from '@models';
import { EvidenceApiUrl } from '@enums';
import { SupplierDictionaryImportActionButtons } from './action-bar-components/supplier-dictionary-import-action-buttons';
import { SupplierDictionaryTakeoverActionButtons } from './action-bar-components/supplier-dictionary-takeover-action-buttons';
import { useStyles } from './supplier-dictionary-action-bar-styles';

interface SupplierDictionaryActionBarProps {
  importSourceUrls: EvidenceApiUrl[];
  takeoverSourceUrls: EvidenceApiUrl[];
}

export function SupplierDictionaryActionBar({
  importSourceUrls,
  takeoverSourceUrls,
}: SupplierDictionaryActionBarProps) {
  const { user } = useContext<UserContext<Me>>(UserContext);
  const {
    source: { data },
  } = useContext<DetailHandle<Supplier>>(DetailContext);

  const classes = useStyles();

  const disableEdit = data?.id !== user?.supplier?.id;

  return (
    <>
      <div className={classes.actionBar}>
        <SupplierDictionaryTakeoverActionButtons
          takeoverSourceUrls={takeoverSourceUrls}
          disableEdit={disableEdit}
        />
        <SupplierDictionaryImportActionButtons
          importSourceUrls={importSourceUrls}
          disableEdit={disableEdit}
        />
      </div>
    </>
  );
}
