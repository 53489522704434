import * as Yup from 'yup';
import { Address, PersonType, PrimaryJournal, Tenant } from '@models';

export function useValidationSchema() {
  return Yup.object<PrimaryJournal>().shape({
    name: Yup.string().nullable().required(),
    leader: Yup.mixed<PersonType>().nullable().required(),
    team: Yup.string().nullable().required(),
    tenant: Yup.mixed<Tenant>().nullable().required(),
    construction: Yup.object().shape({
      name: Yup.string().nullable().required(),
      siteId: Yup.string().nullable().required(),
      type: Yup.mixed().nullable().required(),
    }),
    address: Yup.object<Address>().shape({
      region: Yup.string().nullable().required(),
      municipality: Yup.string().nullable().required(),
    }),
  });
}
