import { useContext, useMemo } from 'react';
import { SupplierDictionariesEnum } from 'src/enums/supplier-dictionaries';
import { AbortableFetch, UserContext, abortableFetch } from '@eas/common-web';
import { EvidenceApiUrl, Permission } from '@enums';

type TakeoverInfo = Record<
  SupplierDictionariesEnum,
  {
    callApi: () => AbortableFetch;
    showButton: boolean;
  }
>;

export function useSupplierDictionariesTakeover(sourceUrls: EvidenceApiUrl[]) {
  const { hasPermission, user } = useContext(UserContext);

  const takeoverInfo = useMemo(() => {
    return sourceUrls.reduce((acc, sourceUrl) => {
      const callApi = () => {
        console.log(user, 'context before takover');
        return abortableFetch(`${sourceUrl}/takeover`, {
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
          method: 'POST',
        });
      };

      /**
       * Check permissions for given sources
       */
      switch (sourceUrl) {
        case EvidenceApiUrl.MECHANISMS:
          acc[SupplierDictionariesEnum.MECHANISM] = {
            callApi,
            showButton: hasPermission(Permission.Mechanism.MECHANISM_TAKEOVER),
          };
          break;
        case EvidenceApiUrl.MECHANISM_CATEGORY:
          acc[SupplierDictionariesEnum.MECHANISM_CATEGORY] = {
            callApi,
            showButton: hasPermission(
              Permission.MechanismCategory.MECHANISM_CATEGORY_TAKEOVER
            ),
          };
          break;
        case EvidenceApiUrl.ABSENCE:
          acc[SupplierDictionariesEnum.ABSENCE] = {
            callApi,
            showButton: hasPermission(Permission.Absence.ABSENCE_TAKEOVER),
          };
          break;
        case EvidenceApiUrl.WORK:
          acc[SupplierDictionariesEnum.WORK] = {
            callApi,
            showButton: hasPermission(Permission.Work.WORK_TAKEOVER),
          };
          break;
        case EvidenceApiUrl.WORK_CATEGORY:
          acc[SupplierDictionariesEnum.WORK_CATEGORY] = {
            callApi,
            showButton: hasPermission(
              Permission.WorkCategory.WORK_CATEGORY_TAKEOVER
            ),
          };
          break;
        case EvidenceApiUrl.TBPP:
          acc[SupplierDictionariesEnum.TBPP] = {
            callApi,
            showButton: hasPermission(Permission.TBPP.TBPP_TAKEOVER),
          };
          break;
        case EvidenceApiUrl.PROFESSION:
          acc[SupplierDictionariesEnum.PROFESSION] = {
            callApi,
            showButton: hasPermission(
              Permission.Profession.PROFESSION_TAKEOVER
            ),
          };
          break;
      }

      return acc;
    }, {} as TakeoverInfo);
  }, [hasPermission, sourceUrls, user]);

  return {
    takeoverInfo,
  };
}
